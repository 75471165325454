@font-face {
  font-family: "typewriter";
  src: url("./assets/SpecialElite.ttf");
}
body {
  margin: 0;
  font-family: "typewriter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 0.5vw;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #b19863;
  border-radius: 10px;
}
